import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../../../environments/environment'
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader, AgmMap } from '@agm/core';
import { GoogleMapsAPIWrapper } from '@agm/core/services';
declare var google: any;
import {InspectionStatusComponent} from '../inspection-status/inspection-status.component';
@Component({
  selector: 'app-property-overview',
  templateUrl: './property-overview.component.html',
  styleUrls: ['./property-overview.component.scss'],
  // providers: [
  //   GoogleMapsAPIWrapper // <---
  // ]
})
export class PropertyOverviewComponent implements OnInit {
  @Input() job: any;
  @Input() jobData : any;
  @Output () notify: EventEmitter<string> = new EventEmitter<string>();
  map_button_status:string = "Show Map";
  map_status:boolean = true;
  constructor(
    public mapsApiLoader: MapsAPILoader,
      // private zone: NgZone,
      private wrapper: GoogleMapsAPIWrapper,
      config : NgbModalConfig,
        private modalService: NgbModal
   ) {
      this.mapsApiLoader = mapsApiLoader;
      // this.zone = zone;
      // this.wrapper = wrapper;
      config.backdrop = 'static';
        config.keyboard = false;
      
  }
  panorama: any;
  loca = {
    lat: 32.815173,
    lng: -116.83905950000002
  };
  userData;
  ngOnInit() {
    this.userData = JSON.parse(localStorage.getItem('user'));
    console.log(this.job);
  }
  
  ngOnChanges(){  
  }

  getStreetMap(){
    this.mapsApiLoader.load().then(() => {
      // this.loca = {
      //   lat: lat,
      //   lng: long
      // };
      if(this.map_button_status === "Show Map"){
          this.map_status = false;
          this.map_button_status = "Hide Map";
      }else{
          this.map_status = true;
          this.map_button_status = "Show Map";
      }
        this.streetMap();
        
        
      });
  }
  
  streetMap() {
    var job_propertices = this.job;
    let lat = parseFloat(job_propertices.property_lat);
      let long = parseFloat(job_propertices.property_long);
    this.panorama = new google.maps.StreetViewPanorama(
        document.getElementById('panozz'), {
            position: {
              lat: lat,
              lng: long
            },
            linksControl: false,
            panControl: false,
            enableCloseButton: false,
            fullscreenControl: false,
            zoomControl: false,
            motionTrackingControl: false,
            addressControl: false

        });
    // console.log(this.pana)
  }
    openFormModal(id) {
        const modalRef = this.modalService.open(InspectionStatusComponent,{size:'lg'});
        modalRef.componentInstance.jobData = this.jobData;
        modalRef.result.then((result) => {
         
          this.jobData = result;
          this.notify.emit(this.jobData);
          // this.getTransactions();
        }).catch((error) => {
            console.log(error);
        });
    }
  onShowMap(){
    this.getStreetMap();
  }


}
